var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValidationObserver", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ invalid }) {
          return [
            _c(
              "popup-base",
              {
                attrs: {
                  title: "Usuários",
                  size: "sm",
                  showModal: _vm.showModal,
                },
                on: {
                  close: function ($event) {
                    _vm.showModal = $event
                  },
                },
              },
              [
                _c(
                  "template",
                  { slot: "content" },
                  [
                    _c(
                      "vs-row",
                      _vm._l(_vm.usuarios, function (user, index) {
                        return _c(
                          "vs-col",
                          { key: index },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                attrs: { "vs-value": user.PersonId },
                                model: {
                                  value: _vm.userIdList,
                                  callback: function ($$v) {
                                    _vm.userIdList = $$v
                                  },
                                  expression: "userIdList",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(user.Name) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "template",
                  { slot: "footer" },
                  [
                    _c(
                      "vs-row",
                      { staticClass: "py-0" },
                      [
                        _c(
                          "vs-col",
                          { attrs: { "vs-type": "flex", "vs-justify": "end" } },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "mr-3",
                                attrs: { color: "primary", type: "border" },
                                on: {
                                  click: function ($event) {
                                    _vm.showModal = false
                                  },
                                },
                              },
                              [_vm._v("\n            Cancelar\n          ")]
                            ),
                            _c(
                              "vs-button",
                              {
                                attrs: { color: "primary", disabled: invalid },
                                on: {
                                  click: function ($event) {
                                    return _vm.salvarVinculos()
                                  },
                                },
                              },
                              [_vm._v("\n            Salvar\n          ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }