<template>
  <ValidationObserver v-slot="{ invalid }">
    <popup-base
      title="Usuários"
      size="sm"
      @close="showModal = $event"
      :showModal="showModal"
    >
      <template slot="content">
        <vs-row>
          <vs-col v-for="(user, index) in usuarios" :key="index">
            <vs-checkbox v-model="userIdList" :vs-value="user.PersonId">
              {{ user.Name }}
            </vs-checkbox>
          </vs-col>
        </vs-row>
      </template>

      <template slot="footer">
        <vs-row class="py-0">
          <vs-col vs-type="flex" vs-justify="end">
            <vs-button
              class="mr-3"
              color="primary"
              type="border"
              @click="showModal = false"
            >
              Cancelar
            </vs-button>

            <vs-button
              color="primary"
              @click="salvarVinculos()"
              :disabled="invalid"
            >
              Salvar
            </vs-button>
          </vs-col>
        </vs-row>
      </template>
    </popup-base>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  props: {
    showPopup: { type: Boolean, default: false },
    profileUniqueId: { type: String },
    profileUsers: { type: Array, default: () => [] }
  },
  data() {
    return {
      userIdList: []
    };
  },
  computed: {
    ...mapGetters("usuario-module", ["usuarios"]),

    showModal: {
      get() {
        return this.showPopup;
      },

      set(value) {
        this.$emit("setShowPopup", value);
      }
    }
  },
  methods: {
    ...mapActions("usuario-module", ["obterUsuarios"]),
    ...mapActions("perfil-module", ["updateUserInProfile"]),
    ...mapMutations("perfil-module", ["updateUserProfile"]),

    async loadObterUsuarios() {
     await this.$onpoint.loading(async () => {
        return  await this.obterUsuarios({filterByActive: true});
      });
    },

    async salvarVinculos() {
      await this.$onpoint.loading(async() => {
        return await this.updateUserInProfile({
          ProfileUniqueId: this.profileUniqueId,
          PersonIds: this.userIdList
        }).then(() => {
          this.$emit("reloadProfile");

          this.showModal = false;
        });
      });
    }
  },
  watch: {
    async showModal(value) {
      if (value) {
        this.userIdList = (this.profileUsers || []).map(x => x.Id);
        await this.loadObterUsuarios();
      }
    }
  }
};
</script>
