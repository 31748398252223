<template>
  <div id="perfil-acesso">
    <div class="flex flex-row-reverse mb-5">
      <vs-button type="border" @click="adicionarPerfil">
        <i class="onpoint-plus icon-font"></i>
        Adicionar perfil de acesso
      </vs-button>
    </div>

    <vs-collapse accordion type="margin">
      <vs-collapse-item
        v-for="(perfil, index) in perfilList"
        :key="index"
        :open="index == 0"
      >
        <template slot="header">
          {{ perfil.Name }}
        </template>
        <vs-row class="pt-0">
          <vs-col class="md:w-1/2 w-full">
            <h6 class="text-primary mb-3">Usuários</h6>

            <div
              class="container-user"
              v-for="(user, indexUser) in perfil.Usuarios"
              :key="indexUser"
            >
              <p>{{ user.Name }}</p>
            </div>

            <div class="flex flex-row-reverse mb-5">
              <vs-button type="flat" @click="vincularUsuarios(perfil)">
                <i class="onpoint-gear-six icon-font"></i>
                Gerenciar usuários do perfil
              </vs-button>
            </div>
          </vs-col>

          <vs-col class="md:w-1/2 w-full">
            <div
              class="mb-8"
              vs-type="flex"
              v-for="(menu, indexMenu) in perfil.Menus"
              :key="`menu-${indexMenu}`"
            >
              <h6 class="text-primary mb-3">Permissões - {{ menu.Name }}</h6>

              <div
                class="container-functionality"
                v-for="(functionality,
                indexFunctionality) in menu.MenuFuncionalities"
                :key="indexFunctionality"
              >
                <i class="onpoint-check-square"></i>
                <p>{{ functionality.Name }}</p>
              </div>
            </div>

            <div class="flex flex-row-reverse">
              <vs-button type="flat" @click="editarPerfil(perfil)">
                <i class="onpoint-gear-six icon-font"></i>
                Configurar perfil {{ perfil.Name }}
              </vs-button>
            </div>

            <div class="flex flex-row-reverse mb-5">
              <vs-button
                color="danger"
                type="flat"
                @click="
                  showConfirmationModal = true;
                  profileUniqueId = perfil.UniqueId;
                "
              >
                <i class="onpoint-trash icon-font"></i>
                Excluir perfil {{ perfil.Name }}
              </vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-collapse-item>
    </vs-collapse>

    <app-dialog
      v-model="showConfirmationModal"
      title=""
      @cancel="showConfirmationModal = false"
      @confirm="excluirPerfil()"
    >
      <template slot="content">
        <h4 class="text-center">
          Deseja realmente remover esse o esse perfil?
        </h4>
      </template>
    </app-dialog>

    <cadastrar-perfil-component
      :showPopup="showPerfilPopup"
      :mode="mode"
      :value="perfilItem"
      @setShowPopup="showPerfilPopup = $event"
    ></cadastrar-perfil-component>

    <vincular-usuarios-perfil-component
      :showPopup="showVinculoUsuarioPopup"
      :profileUniqueId="(perfilItem || {}).UniqueId"
      :profileUsers="(perfilItem || {}).Usuarios"
      @setShowPopup="showVinculoUsuarioPopup = $event"
      @reloadProfile="loadGetProfileByCompanyId"
    ></vincular-usuarios-perfil-component>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CadastrarPerfilComponent from "@/components/usuario/CadastrarPerfilComponent.vue";
import VincularUsuariosPerfilComponent from "./components/VincularUsuariosPerfil.vue";


export default {
  components: {
    CadastrarPerfilComponent,
    VincularUsuariosPerfilComponent
  },
  data() {
    return {
      showPerfilPopup: false,
      showVinculoUsuarioPopup: false,
      showConfirmationModal: false,
      mode: 0,
      perfilItem: null,
      profileUniqueId: null
    };
  },
  computed: {
    ...mapGetters("perfil-module", ["perfilList"])
  },
  methods: {
    ...mapActions("perfil-module", ["getProfileByCompanyId", "deleteProfile"]),

    async loadGetProfileByCompanyId() {
      await this.$onpoint.loading(async() => {
        return await this.getProfileByCompanyId();
      });
    },

    async excluirPerfil() {
      await this.$onpoint
        .loading(async () => {
          return await this.deleteProfile(this.profileUniqueId).then(() => {
            this.showConfirmationModal = false;
            this.profileUniqueId = null;
            this.$onpoint.successModal("Perfil excluído com sucesso");
          });
        })
        .catch(error => {
          this.showConfirmationModal = false;
          this.profileUniqueId = null;
          this.$onpoint.errorModal(error.response.data.Errors);
        });
    },

    adicionarPerfil() {
      this.showPerfilPopup = true;
      this.mode = 0;
    },

    editarPerfil(item) {
      this.perfilItem = item;
      this.showPerfilPopup = true;
      this.mode = 1;
    },

    vincularUsuarios(item) {
      this.perfilItem = item;
      this.showVinculoUsuarioPopup = true;
    }
  },
  async mounted() {
    await this.loadGetProfileByCompanyId();
  },
  created() {
    this.$appInsights.trackPageView({
      name: "lista-perfis",
      url: window.location.href
    });
  }
};
</script>

<style lang="scss">
#perfil-acesso {
  .vs-collapse {
    padding-right: 0 !important;
    padding-left: 0 !important;

    .vs-collapse-item--header {
      max-height: 40px;
      font-size: 1rem !important;
      background: #e4e1e1;
      font-weight: bold;
      align-items: center;
      display: flex;

      .icon-header .vs-icon {
        font-size: 2rem !important;
        color: rgba(var(--vs-primary), 1);
      }
    }

    .container-functionality,
    .container-user {
      width: 100%;
      display: inline-flex;
      padding-bottom: 0.5rem;

      i {
        margin-right: 0.5rem !important;
        margin-top: 0.2rem !important;
        color: #b9b9b9;
        background: white;
        height: fit-content;
      }
    }

    .container-functionality i {
      margin-right: 0.5rem !important;
      margin-top: 0.2rem !important;
      color: #b9b9b9;
      background: white;
      height: fit-content;
    }
  }

  .vs-collapse.margin .vs-collapse-item {
    background: #f8f8f8;
    box-shadow: 0 0;
    border: none !important;
    border-radius: 0;

    .vs-collapse-item--content {
      cursor: default;
      overflow-y: auto;
    }
  }
}
</style>

<style lang="scss" scoped>
  .onpoint-plus::before{
    position: relative;
    top: 2px;
    margin-right: 2px;
    font-size:16px;
  }
  
  .onpoint-gear-six::before{
    position: relative;
    top: 2px;
    margin-right: 2px;
    font-size:16px;
  }

  .onpoint-trash::before{
    position: relative;
    top: 2px;
    margin-right: 2px;
    font-size:16px;
  }
</style>
